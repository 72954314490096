<template>
  <div>
    <h1>Gestionnaire de client</h1>
    <p>
      Découvrez mon application web (SAAS) de gestion complète, conçue pour simplifier vos opérations commerciales.
      De la prospection client à la facturation, en passant par la gestion des autorisations et la sécurité des données,
      ma solution offre une suite d'outils robustes dans le cloud. Accompagnée d'un support réactif et
      d'une infrastructure fiable, elle vous permet de rester concentré sur l'essentiel : la croissance de votre entreprise.
    </p>

    <h2 style="padding-top: 1em">Prospection Client</h2>
    <p>Gérez la prospection de clients efficacement de la prise de contact à la transformation en client.</p>
    <div class="img-container">
      <img src="img/webapp/clients.png" alt="citron-digital clients">
      <img src="img/webapp/client_edit.png" alt="citron-digital clients edition">
    </div>
    <h2>Devis et Facturation</h2>
    <p>Créez et gérez facilement des devis et factures, avec génération de PDF.</p>
    <div class="img-container">
      <img src="img/webapp/contracts.png" style="flex-basis: 100%" alt="citron-digital contrats">
      <img src="img/webapp/contract_edit.png" alt="citron-digital contrats edition">
      <img src="img/webapp/contract_edit_infos.png" alt="citron-digital contrats edition informations">
    </div>
    <h2>Encaissements</h2>
    <p>Gérez vos encaissements par chèque, virement ou espèce.</p>
    <div class="img-container">
      <img src="img/webapp/collections_add.png" alt="citron-digital encaissement ajout">
      <img src="img/webapp/collections_list.png" alt="citron-digital encaissement liste">
    </div>
    <h2>Collaboration</h2>
    <p>Vos collaborateurs peuvent travailler ensemble via un espace entreprise partagé.</p>
    <div class="img-container">
      <img src="img/webapp/users_list.png" alt="citron-digital utilisateurs liste">
    </div>
    <h2>Gestion des Autorisations</h2>
    <p>Configurez précisément les droits de vos utilisateurs pour un contrôle total.</p>
    <div class="img-container">
      <img src="img/webapp/users_edit.png" alt="citron-digital utilisateurs modifications">
    </div>
    <h2>Statistiques et KPI</h2>
    <p>Visualisez vos performances avec des statistiques et indicateurs clés.</p>
    <h2>Processus optimisé</h2>
    <p>Suivez chaque étape du processus de manière intuitive, de la prospection des clients aux statistiques.</p>
    <h2>Sécurité</h2>
    <p>Vos données sont sécurisées avec un cryptage de bout en bout.</p>
    <h2>Journal d'activité</h2>
    <p>Consultez un journal détaillé des actions effectuées par les utilisateurs.</p>
    <h2>Infrastructure cloud</h2>
    <p>Bénéficiez d'une sauvegarde automatique et d'une disponibilité 24/7 sans configuration.</p>
    <h2>Support</h2>
    <p>Obtenez un support réactif avec une ligne directe vers le dirigeant de l'entreprise.</p>

    <h1 class="contact">Intéréssé ? alors <router-link :to="'/contact'" class="hyperlink">contactez-moi</router-link> !</h1>
  </div>
</template>

<script>
    export default {
        name: 'WebApplicationView'
    }
</script>

<style lang="stylus" scoped>
  @import '~/src/common/style/constants.styl';

  h1
    padding-top 1em
  .contact
    text-align center

  .img-container
    width: 100%
    display flex
    flex-direction: column;
    align-items: center;
    img
      padding-top 1em
      width 100%
      max-width 1000px

  @media only screen and (min-width: DESKTOP_SIZE)
    .img-container
      width: 100%
      display flex
      flex-wrap wrap
      flex-direction row
      align-items center
      justify-content center
      img
        padding-left 1em
        padding-right 1em
        padding-top 1em
        width 45%
</style>