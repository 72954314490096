<template>
  <router-link :to="'/'">
    <div class="header">
      <div class="right-part">
        <h1><strong>CITRON</strong> DIGITAL</h1>
        <!--<h2>Par Franck Besson</h2>-->
        <!--<p>Site Web / Application Web (SAAS)</p>-->
      </div>
    </div>
  </router-link>
</template>

<script>
    export default {
        name: 'HeaderComponent',
    }
</script>

<style lang="stylus" scoped>
@import '~/src/common/style/constants.styl';
a
  height 75px // 150px pour la bannière
  display block
  text-decoration none
.header
  display flex
  justify-content: center;
  align-items: center;
  background-color #030e25 // Commenter pour la bannière
  height 75px // 150px pour la bannière
  width 100%
  .right-part
    display flex
    flex-direction column
    justify-content center
    padding-right 1em
    *
      margin 0
    h1
      font-size 1.8em
      strong
        color THEME_COLOR_SECONDARY
  .separator
    height 5.2em
    width 1px
    background-color #0b1323
    margin-right 1.5rem
    margin-left 1.5rem
  .logo-container
    padding-left 1em
    display flex
    justify-content: center;
    align-items: center;
    width 8em
    height 100%
    img
      width 100%
  &:hover
    cursor pointer
    background-color rgba(3, 14, 37, 0.8)
</style>