<template>
  <div class="footer"><p>Made by Franck Besson | 2024 Copyright</p></div>
</template>

<script>
    export default {
        name: 'FooterComponent',
    }
</script>

<style lang="stylus" scoped>
@import '~/src/common/style/constants.styl';

.footer
  height 3em
  padding-top 6rem
  display flex
  justify-content center
  p
    font-size 0.8em
</style>