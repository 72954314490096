<template>
  <HeaderComponent/>
  <FooterComponent/>
</template>

<script>
    import FooterComponent from "@/components/FooterComponent.vue";
    import HeaderComponent from "@/components/HeaderComponent.vue";

    export default {
        name: 'ArticlesView',
      components: {HeaderComponent, FooterComponent},
    }
</script>

<style lang="stylus" scoped>
@import '~/src/common/style/constants.styl';
</style>