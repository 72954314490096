<template>
  <div>
    <h1>Site vitrine</h1>
    <p>
      Un site vitrine est un site web qui présente une entreprise, ses produits ou services sans fonctionnalité de
      vente en ligne. Son objectif principal est d'informer les visiteurs sur l'entreprise et son offre.

      Vous cherchez à mettre en avant votre entreprise sur internet ?
      Découvrez mon expertise dans la création de sites vitrines qui mettent en avant <strong>votre marque</strong>,
      <strong>vos créations</strong> et <strong>vos valeurs</strong>.
    </p>

    <h1>Mes forces</h1>
    <h2>Originalité</h2>
    <p>Je peux réaliser tout ce que vous voulez, si vous avez un désir particulier (design, infographie ...) il n'y a aucun souci.</p>

    <h2>Référencement et réseaux sociaux</h2>
    <p>Le site sera <strong>optimisé</strong> pour les recherches Google et pour être partagé sur les réseaux sociaux.</p>

    <h2>Proximité</h2>
    <p>Vous aurez affaire à moi durant tout le processus de création et même après.</p>

    <h2>Simplicité</h2>
    <p>Je me charge de tout, location de serveur, nom de domaine, déploiement ...</p>

    <h2>Portabilité</h2>
    <p>Et si demain vous souhaitez changer de prestataire, je me charge de tout lui transmettre <strong>gratuitement</strong>.</p>



    <!--

    <h1> A proposer uniquement si on remplis un formulaire avec mail et numéro de téléphone</h1>
    <h2>Conception et dévéloppement</h2>
    <p>Je vous propose le pack de base qui comprends : </p>
    <ul>
      <li>Un menu en haut</li>
      <li>Une grande image en dessous</li>
      <li>5 sections (ça peut être la présentation de vos créations, votre contact, vos réseaux sociaux ou présentation de votre entreprise ou de vous, ça peut même être sur mesure selon vos besoins)</li>
      <li>Un bas de page.</li>
    </ul>

    <p>
      Plusieurs allée retour entre vous et moi afin de s'assurer que le site sera conforme à vos attente
      à
    </p>

    <h2>Livraison</h2>
    <p>La livraison peut se faire sur n'omporte quel serveur si vous en possédez déjà un, sinon je peux m'occuper de tout mettre en place, dans tout les cas ces frais sont compris dans le tarif de base</p>

    <h2>Maintenance</h2>
    <p>La maintenance comprend : </p>
    <ul>
      <li>La facturation annuel du serveur.</li>
      <li>Le nom de domaine.</li>
      <li>Un backup de mon côté si il y avait un problème, rien ne serait perdu.</li>
      <li>L'intégralité de la gestion est réalisée de mon côté (s'il y a un soucis tu m'appelle et je remets le site en ligne au plus vite), vous n'aurez rien à faire.</li>
      <li>La gestion du certificat SSL (en gros le site est sécurisé ça rassure les utilisateurs, c'est le screen shot).</li>
      <li>La gratuité de ma main d'œuvre si vous souhaitez changer d'hébergeur, je m'occupe de tout avec le prochain prestataire ou vous-même.</li>
    </ul>

    <h2>Tarification</h2>
    <p>
      <strong>1000€</strong>
      <strong>100€</strong> TTC annuel
      Si vous souhaite plus de chsoe comme des onglet (250€ par onglet) plus de section (autour des 200€) ... je peux bien sur ajouter cela, nous en discuterons entre nous
    </p>

    -->

    <h1 class="contact">Intéréssé ? alors <router-link :to="'/contact'" class="hyperlink">contactez-moi</router-link> !</h1>
  </div>
</template>

<script>
    export default {
        name: 'ShowcaseWebsiteView'
    }
</script>

<style lang="stylus" scoped>
    @import '~/src/common/style/constants.styl';

    strong
      color THEME_COLOR_SECONDARY

    h1.contact
      padding-top 1em
      text-align center
</style>